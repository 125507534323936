<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="预订单状态">
              <DictSelect
                field="advanceOrder"
                :value.sync="searchData.orderStatus"
                style="width: 100%"
                placeholder="请选择订单状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="申请状态">
              <DictSelect
                field="registrationStatus"
                :value.sync="searchData.registrationStatus"
                style="width: 100%"
                placeholder="请选择申请状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户类型">
              <a-select placeholder="请选择" v-model="searchData.customerType">
                <a-select-option :value="item.id" v-for="(item, index) in cusTypeData" :key="index">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户来源">
              <a-select placeholder="请选择" v-model="searchData.customer_source">
                <a-select-option :value="item.id" v-for="(item, index) in customerSourceList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="订单类型">
              <a-select placeholder="请选择" v-model="searchData.orderType">
                <a-select-option :value="item.id" v-for="(item, index) in orderTypeData" :key="index">{{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="订单类型">
              <DictSelect
                field="cusOrderType"
                :value.sync="searchData.orderType"
                style="width: 100%"
                placeholder="请选择"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="经销商">
              <a-select placeholder="请选择经销商" v-model="searchData.dealerId">
                <a-select-option :value="item.id" v-for="(item, index) in dealerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="门店">
              <a-select placeholder="请选择门店" v-model="searchData.storeId">
                <a-select-option :value="item.id" v-for="(item, index) in storeData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="员工">
              <a-select placeholder="请选择员工" v-model="searchData.managerId">
                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户信息">
              <a-input v-model="searchData.generalConditions" placeholder="客户姓名/手机号"></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="所属信息">
              <a-input v-model="searchData.searchName" placeholder="所属门店/员工"></a-input>
            </a-form-model-item>
          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="操作日期">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
<!--      <div>
        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="editEnabel && selectedRows.length == 1" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button type="primary" @click="handOver">移交</a-button>
        <a-button v-if="setDealt && selectedRows.length == 1" type="primary" @click="toHandler('set_dealt')"
          >设置已成交</a-button
        >
        <a-button v-if="removeEnable && selectedRows.length == 1" type="danger" @click="toHandler('del')">删除</a-button>
      </div>-->
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        :scroll="{ x: 2100 }"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="orderType" slot-scope="item">
          <span v-if="item == 1">零售</span>
          <span v-if="item == 2">项目</span>
        </span>
        <span slot="orderStatus" slot-scope="item">
          <span v-if="item == 0">未申请</span>
          <span v-if="item == 1">已申请</span>
          <span v-if="item == 2">已成交</span>
          <span v-if="item == 3">已失效</span>
          <span v-if="item == 4">已丢单</span>
          <span v-if="text === 5">已无效</span>
          <span v-if="text === 6">过期</span>
        </span>
        <span slot="registrationStatus" slot-scope="item">
          <span v-if="item == 0">未设置</span>
          <span v-if="item == 1">首次申请</span>
          <span v-if="item == 2">二次申请</span>
          <span v-if="item == 3">申请成功</span>
        </span>
        <span slot="province" slot-scope="text, row"
          >{{ row.province }}{{ row.city }}{{ row.area }} {{ row.village }}{{ row.building }}{{ row.unit
          }}{{ row.room }}</span
        >
        <span slot="customerSource" slot-scope="text, row"
          >【{{ row.customerName }}】{{ row.companyName }}-{{ row.channelType }}</span>
      </a-table>
      <!-- 编辑表单 -->
<!--      <DealerCustomerInfo-edit-modal ref="DealerCustomerInfoEditModal" @reload="getData" ></DealerCustomerInfo-edit-modal>
      <SetDealtModal ref="SetDealtModal" @reload="getData"></SetDealtModal>-->
      <!-- 选择移交员工 -->
<!--      <CheckStaffPage ref="CheckStaffPage" @reload="getData"></CheckStaffPage>-->
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
// import DealerCustomerInfoEditModal from './components/DealerCustomerInfoEditModal.vue'

import {
  listFirmCustomerInfo,
} from './api/DealerCustomerInfoApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerCustomerInfo',
  components: {},
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      advanceOrderList: [],
      customerTypeList: [],
      customerSourceList: [],
      orderTypeList: [],
      cusTypeData: [],
      dealerData: [],
      storeData: [],
      managerData: [],
      orderTypeData: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listFirmCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.getSearchData()
    },
    getSearchData() {
      this.axios.get(`/api/base/system/dictionary/selectByCodeFirm/客户类型`).then((res) => {
        if (res.code == 200) {
          this.cusTypeData = res.body
        }
      })
      this.axios.get(`/api/base/system/dictionary/selectByCode/订单类型`).then((res) => {
        if (res.code == 200) {
          this.orderTypeData = res.body
        }
      })
      this.axios.get(`/api/dealer/dealer/dealerInfo/dealerListForFirm`).then((res) => {
        if (res.code == 200) {
          this.dealerData = res.body
        }
      })
      this.axios.get(`/api/dealer/store/dealerStore/storeListForFirm`).then((res) => {
        if (res.code == 200) {
          this.storeData = res.body
        }
      })
      this.axios.get(`/api/base/system/dealer/manager/managerListForFirm`).then((res) => {
        if (res.code == 200) {
          this.managerData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },

    // 移交
    /*handOver() {
      if(this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.$refs.CheckStaffPage.setRowData(this.selectedRowKeys, 'hand_over')
    },*/

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    /*toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerCustomerInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerCustomerInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerCustomerInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'set_dealt':
          _this.$refs.SetDealtModal.show(_this.selectedRows[0])
          break
      }
    },*/
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
